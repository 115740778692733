<template>
  <div>
    <div class="row wrapper border-bottom white-bg page-heading">
      <div class="col-lg-10">
        <h2>Compras</h2>
        <ol class="breadcrumb">
          <li>
            Proveedores
          </li>
          <li>
            <strong>{{modo == 'nuevo'?'Nuevo':'Edición'}}</strong>
          </li>
        </ol>
      </div>
    </div>

    <div class="wrapper wrapper-content">
      <div class="row">
        <div class="col-md-9">
          <div class="ibox">
            <div class="ibox-title">
              <h5>
                {{modo == 'nuevo'?'Nuevo':'Editar'}}
                proveedor
              </h5>
              <div class="ibox-tools">
                <a @click="cerrar()">
                  <i class="fa fa-close"></i>
                </a>
              </div>
            </div>
            
            <div class="ibox-content">
              <div class="row">
                <div class="col-md-4">
                  <label>Nombre</label>
                  <input
                    class="form-control"
                    v-model="proveedor.nombre"
                  />
                </div>
                <div class="col-md-4">
                  <label>RFC</label>
                  <input
                    class="form-control"
                    v-model="proveedor.rfc"
                  />
                </div>
                <div class="col-md-4">
                  <label>Calle</label>
                  <input
                    class="form-control"
                    v-model="proveedor.calle" 
                  />
                </div>
              </div>
              
              <br>

              <div class="row">
                <div class="col-md-2">
                  <label>Numero exterior</label>
                  <input
                    class="form-control"
                    v-model="proveedor.numero_exterior"
                  />
                </div>
                <div class="col-md-2">
                  <label>Numero interior</label>
                  <input
                    class="form-control"
                    v-model="proveedor.numero_interior"
                  />
                </div>
                <div class="col-md-4">
                  <label>Colonia</label>
                  <input
                    class="form-control"
                    v-model="proveedor.colonia"
                  />
                </div>
                <div class="col-md-4">
                  <label>Delegación o municipio</label>
                  <input
                    class="form-control"
                    v-model="proveedor.municipio" 
                  />
                </div>
              </div>
              
              <br>

              <div class="row">
                <div class="col-md-2">
                  <label>Codigo postal</label>
                  <input
                    class="form-control"
                    v-model="proveedor.codigo_postal"
                  />
                </div>
                <div class="col-md-4">
                  <label>Estado</label>
                  <input
                    class="form-control"
                    v-model="proveedor.estado"
                  />
                </div>
                <div class="col-md-4">
                  <label>Pais</label>
                  <input
                    class="form-control"
                    v-model="proveedor.pais" 
                  />
                </div>
              </div>

              <br>

              <div class="row">
                <div class="col-md-4">
                  <label>Correo electronico</label>
                  <input
                    class="form-control"
                    v-model="proveedor.correo"
                  />
                </div>
                
                <div class="col-md-6">
                  <label>Teléfonos</label>

                  <div class="row">
                    <div class="col-xs-4">
                      <select class="form-control" v-model="tipoTelefono">
                        <option value="celular">Celular</option>
                        <option value="casa">Casa</option>
                        <option value="trabajo">Trabajo</option>
                        <option value="otro">Otro</option>
                      </select>
                    </div>
                    <div class="col-xs-8">
                      <div class="input-group">
                        <input
                          type="text"
                          class="form-control"
                          v-model="numeroTelefono"
                          v-on:keyup.enter="agregarTelefono()"
                        />
                        <span class="input-group-btn">
                          <button
                            class="btn btn-default"
                            @click="agregarTelefono()"
                            type="button"
                          >
                            <i class="fa fa-plus"></i>
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div class="table-responsive">
                    <table id="tbl-telefonos" class="table table-hover">
                      <thead>
                        <tr>
                          <th>Tipo</th>
                          <th>Número</th>
                          <th>
                            <i class="fa fa-minus"></i>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(telefono, index) in proveedor.telefonos"
                          :key="telefono.id"
                        >
                          <td>
                            {{ telefono.tipo }}
                          </td>
                          <td>
                            {{ telefono.numero }}
                          </td>
                          <td>
                            <button
                              class="btn btn-danger btn-xs"
                              @click="eliminarTelefono(index)"
                            >
                              <i class="fa fa-minus"></i>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <br>

              <div class="row">
                <div class="col-md-6">
                  <label>Datos para transferencia bancaria</label>
                  <textarea
                    class="form-control"
                    rows="5"
                    v-model="proveedor.codigo_postal"
                  ></textarea>
                </div>
                <div class="col-md-6">
                  <label>Datos para depósito</label>
                  <textarea
                    class="form-control"
                    rows="5"
                    v-model="proveedor.estado"
                  ></textarea>
                </div>
              </div>
              
              <br>

              <div class="row">
                <div class="col-md-12">
                  <label>División de familias de artículos</label>
                  <div class="row">
                    <div class="col-xs-6">
                      <multiselect
                        v-model="proveedor.familias"
                        placeholder="Buscar familia de articulos"
                        selectLabel="Presione enter para seleccionar"
                        selectedLabel="Seleccionado"
                        deselectLabel="Presione enter para eliminar"
                        label="division"
                        track-by="id"
                        :options="opciones_familias"
                        :multiple="true"
                        :taggable="false"
                      ></multiselect>
                    </div>
                    <div class="col-xs-6">
                      <div class="checkbox checkbox-success checkbox-circle">
                        <input id="provedorPrincipal" type="checkbox" v-model="proveedor.proveedor_principal">
                        <label for="provedorPrincipal">Proveedor principal para las familias de artículos seleccionadas</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div class="text-right">
            <button @click="cerrar()" class="btn btn-default">
              <i class="fa fa-angle-left"></i> Atrás
            </button>
            &nbsp;
            <button
              type="button"
              class="btn btn-primary"
              @click="modo=='nuevo' ? guardar():actualizar()"
            >
              <i class="fa fa-check"></i>
              {{modo=='nuevo' ? 'Guardar':'Actualizar'}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</template>

<script>
import FamiliaArticulosSrv from '@/services/inventario/FamiliaArticulosSrv.js'
import Multiselect from 'vue-multiselect'


export default {
  components: {
    Multiselect
  },
  data: function() {
    return {
      modo: 'nuevo',
      proveedor:{
        nombre: '',
        rfc: '',
        calle: '',
        numero_exterior: '',
        numero_interior: '',
        colonia: '',
        municipio: '',
        codigo_postal: '',
        estado: '',
        pais: '',
        correo: '',
        telefonos: [],
        transferencia_bancaria: '',
        datos_deposito: '',
        familias:[],
        proveedor_principal: false
      },
      numeroTelefono: '',
      tipoTelefono: 'celular',

      opciones_familias: []
    }
  },

  created: function () {
    var self = this
    if(self.$route.path.indexOf('proveedores/nuevo') != -1) self.modo = 'nuevo'
    else self.modo = 'edicion'

    self.cargarFamilias()
  },

  methods: {
    actualizar: function() {
      iu.msg.success('Se actualizo correctamente el proveedor')
    },

    agregarTelefono: function() {
      var self = this

      self.proveedor.telefonos.push({
        numero: self.numeroTelefono,
        tipo: self.tipoTelefono
      })
      self.numeroTelefono = ''
      self.tipoTelefono = 'celular'
    },

    atras: function() {
      this.$router.go(-1)
    },

    cargarFamilias: function() {
      var self = this

      let params = {
        sin_paginacion: true,
        solo_idfamdiv:true
      }

      FamiliaArticulosSrv.familiasJSON(params).then(response => {
        let page = response.data
        self.opciones_familias = page
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (error) {
          mensaje = 'No se pudieron cargar las familias de articulos'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cerrar: function() {
      this.atras()
    },

    eliminarTelefono: function(index) {
      var self = this

      self.proveedor.telefonos.splice(index, 1)
    },

    guardar: function() {
      iu.msg.success('Se guardo correctamente el proveedor')
    }
  }

}
</script>

